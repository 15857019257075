section {
  color: white;
  margin: 0;
}

.sectionContent {
  height: max-content;
  padding: 16px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 978px) {
    flex-direction: row;
    max-width: 1200px;
  }
}