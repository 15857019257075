body {
  margin: 0;
  font-family: 'Georgia', Georgia, "Times New Roman", serif;
}

a {
  text-decoration: none;
  color: white;
}

html {
  scroll-behavior: smooth;
}