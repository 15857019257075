.navbarWrapper {
  width: 100%;
  background: #2e302d;
  min-height: 70px;
  position: fixed;
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.5);
  z-index: 9;
  opacity: 0;
  transition: opacity ease-in-out 300ms;
}

.navbarWrapper.active {
  opacity: 1;
}

.navbarContent {
  color: white;
  padding: 16px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.staticContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
}

.expandibleContent {
  display: none;
}

.expandibleContent.active {
  display: block;
}

.navbarTitle {
  font-size: 21px;
}

.navbarContent ul {
  text-align: center;
  list-style: none;
  padding-left: 0;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    display: flex;
    justify-content: space-between;
  }
}

.navbarContent li {
  padding: 10px 0px;
  font-size: 18px;
}

.desktop-navList {
  width: 30%;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  width: 30px;
}