.splash {
  padding: 40px 0px 80px;

  @media only screen and (min-width: 978px) {
    padding: 40px 0px;
    height: 75vh;
  }
}

.headshot {
  width: 100%;
  max-width: 500px;
}

.splashLinks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 1px;
  
  a {
    text-align: center;
  }
  & .button {
    margin: 12px;
  }
  /* flex-wrap: wrap; */
}