.contact-wrapper {
  margin: 50px auto;

  @media only screen and (min-width: 978px) {
    justify-content: space-between;
  }
}

.contact-pic {
  border-radius: 50%;
}

.contact-list {
  margin-top: 15px;
  text-align: center;

  @media only screen and (min-width: 978px) {
    text-align: left;
  }

  &>p {
    margin: 8px 0px;
  }

  & a {
    color: black;
    text-decoration: underline;
  }
}

.insta-img {
  max-width: 300px;
  margin-bottom: 10px;

  @media only screen and (min-width: 978px) {
    max-width: 150px;
  }
}

.insta-row {
  display: flex;
  flex-direction: column;
  margin-top: 15px;



  @media only screen and (min-width: 978px) {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 150px);
    column-gap: 10px;
    margin-top: 50px;
  }
}