.carousel {
  width: 100%;
  margin: 10px auto;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    width: 80%;
    margin: 20px auto;
  }
}

.player {
  width: 100%;
  aspect-ratio: 16 /9;
}
.carousel-body {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 978px) {
    height: 500px;
    margin: 30px 0px;
  }
}
