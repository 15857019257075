.thin {
  font-weight: normal;
}

.darkGreen {
  background: #2e302d;
}

.midGreen {
  background: #607170;
}

.iceBlue {
  background: #a3b4bf;
}

.whiteBg {
  background: white;
  color: black;
}
.choreoGreen {
  background: #A3AB78;
}

.dropShadow {
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.1);
}

.fifty {
  flex: 1;
  height: 100%;
}

.thirty {
  flex: 1;
  height: 100%;
}

.sixty {
  flex: 2;
  height: 100%;
}

.onehundred {
  flex: 1;
  height: 100%;
}

.centerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.resumeSection {
  padding: 20px 0px;

  @media only screen and (min-width: 978px) {
    padding: 40px 0px;
  }
}

.resume {
  width: 100%;
  margin-bottom: 20px;
  margin: auto;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    width: auto;
    height: 650px;
    transition: transform ease-in-out 300ms;
    cursor: pointer;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.infoCard {
  text-align: center;
  margin: 16px;
  padding: 16px;
  border-radius: 5px;
  min-width: 75%;
}

.reel {
  width: 80%;
  height: 80vh;
}

.hideMobile {
  display: none;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    display: block;
  }

}

.hideDesktop {
  display: block;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    display: none;
  }

}