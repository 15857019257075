.coffeeChatWrapper {
  font-family: 'Palatino';
  background: #607170;
  padding: 30px;
  height: 100vh;
  color: white;
}
.coffeeChatContent {
  max-width: 800px;
  margin: 0 auto;
  
  a {
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
  
  ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 8px;
  }
}
.twoCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    flex-direction: row;
  }
}
.left {
  flex: 1;
  border-right: 0px;
  border-bottom:  1px solid white;
  text-align: center;
  padding-bottom: 40px;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    border-right: 1px solid white;
    border-bottom: 0px;
    padding-bottom: 0px;
  }
}
.right {
  flex: 2;
  text-align: right;
  font-size: 28px;
  padding-right: 30px;
  
  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    font-size: 42px;
    padding-right: 0px;
  }
}
.phrase {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 0px;
}
.signature {
  margin-top: 22px;
  
}