.carousel {
  width: 100%;
  margin: 10px auto;

  /* DESKTOP */
  @media only screen and (min-width: 978px) {
    width: 80%;
    margin: 20px auto;
  }
}

.carousel-body {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 978px) {
    height: 500px;
    margin: 30px 0px;
  }
}

.carousel-player {
  width: 100%;
  aspect-ratio: 16 /9;
}

.carousel-list {
  display: grid;
  padding: 0px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 10px;
  list-style: none;
  padding-left: 0px;

  @media only screen and (min-width: 978px) {
    grid-template-columns: repeat(5, 1fr);
    padding-left: 0px;
  }
}

.carousel-item {
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform ease-in-out 300ms;
  background: #607170;
  color: white;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &:hover {
    transform: scale(1.1);
  }
}