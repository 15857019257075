.button {
  border: 1px solid white;
  background: transparent;
  color: white;
  padding: 7px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms;
  min-width: 120px;
  margin: 16px;
  font-size: 14px;
  font-family: "Georgia", Georgia, "Times New Roman", serif;

  &:hover {
    background: white;
    color: black;
  }
}

.download-button {
  padding: 16px 30px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  background: #2e302d;
}

.link-button {
  padding: 7px;
  font-size: 14px;
}